<template>
  <div class="mimesis" v-show="show">
    <div
      class="m-mask"
      :style="{ height: maskHeight + 'px' }"
      @click="close"
    ></div>
    <div class="m-body" :style="{ left: bodyLeft + 'px', top: bodyTop + 'px' }">
      <i
        :class="{
          'sdnm-sigh_circular': !showRightIcon,
          ycolor: !showRightIcon,
          'sdnm-correct_circular': showRightIcon,
          gcolor: showRightIcon,
        }"
        v-if="showIcon"
      ></i>
      <div class="all-content">
        <div class="m-head">
          <label class="title">{{ title }}</label>
        </div>
        <div class="m-content" :class="{ width192: showIcon }">
          <slot></slot>
        </div>
      </div>
      <div class="m-foot">
        <button
          class="btn sure"
          :class="{ 'danger-btn': isDangerous, 'warn-btn': !isDangerous }"
          @click="save"
          v-if="!btnLoad"
        >
          {{ saveBtn }}
        </button>
        <load-button
          class="btn sure"
          :class="{ 'danger-btn': isDangerous, 'warn-btn': !isDangerous }"
          :name="saveBtn"
          :disabled="btnLoadDisabled"
          :callback="saveCallBack"
          v-if="btnLoad"
        ></load-button>
        <button class="btn thin close" @click="close">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module components/mimesis
 * @desc 速商 拟态窗
 * @version 0.0.1
 * @author songkexin <qijiajun@rongyi.com>
 * @date 2021-07-12
 * @copyright 2021
 */
export default {
  name: "mimesis",
  props: {
    /**
     * 是否显示拟态框
     */
    show: {
      type: Boolean,
      default: !true,
    },
    /**
     * 是否显示警告icon
     */
    showIcon: {
      type: Boolean,
      default: !true,
    },
    /**
     * 显示警告icon或者正确icon
     */
    showRightIcon: {
      type: Boolean,
      default: !true,
    },
    /**
     * 按钮样式 !true是警告样式，true是危险样式
     */
    isDangerous: {
      type: Boolean,
      default: !true,
    },
    /**
     * 设置拟态框标题
     */
    title: {
      type: String,
      default: "提示", // 标题
    },
    /**
     * 设置确定按钮文字
     */
    saveBtn: {
      type: String,
      default: "确定",
    },
    closeCallBack: {}, // 点击关闭的回调方法
    saveCallBack: {}, // 点击关闭的回调方法
    btnLoadDisabled: {
      type: Boolean,
      default: false,
    },
    btnLoad: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      maskHeight: 0,
      bodyLeft: 0,
      bodyTop: 0,
    };
  },
  methods: {
    /**
     * 确定弹出层位置
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-07-07
     */
    getLocation() {
      let dh = document.body.clientHeight;
      let dw = document.body.clientWidth;
      this.maskHeight = dh;
      this.bodyLeft = dw / 2 - 200;
      this.bodyTop = dh / 2 - 150;
    },
    close() {
      this.closeCallBack();
    },
    save() {
      this.saveCallBack();
    },
  },
  mounted() {
    this.getLocation();
    window.addEventListener(
      "resize",
      () => {
        this.getLocation();
      },
      false
    );
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
</style>
